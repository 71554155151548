import { userRepresentableDetails } from "../../../helper";
import { useTranslation } from "react-i18next";
import React, { ReactElement, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PlanCard from "./PlanCard";
import { getCartPlans, getRateModeType } from "../../../../redux/features/health/cart";
import { PlanTypes } from "../../../constant";
import { Button } from "react-bootstrap";
import { IHealthFamilyDetails } from "../../../../modules/health/utils/interfaces";
import { sortDocument, sortRequiredDocumentReviewDetails } from "../../../../shared/helper";
import { getCurrentPayable } from "../../../../redux/features/health/cart";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Config } from "../../../../config";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { ResetAutoCart } from "../../../../redux/features/health/cart";
import { createOrder, getCartDetailById, getPaymentMethod } from "../../../../modules/health/services/order";
import { StatusCode } from "../../../../shared/constant";
import { updateCartBarStatus } from "../../../../redux/features/health/cart";

interface IProps {
  onCartRemove: (planID: number, cartID: string, planType: string) => void;
  onClose: Function;
  cart: any;
}

const CartBody = ({ onCartRemove, onClose , cart }: IProps): ReactElement => {
  const [selectedPayment, setSelectedPayment] = useState<string>("otherPayment");
  const handlePaymentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedPayment(event.target.value);
  };
  const { t } = useTranslation();
  const plans = useSelector(getCartPlans);
  const rateModeType = useSelector(getRateModeType);

  const nonParentPlanType = Object.keys(plans).filter(planType => planType !== PlanTypes.PARENT)?.[0];

  const nonParentPlan = plans?.[nonParentPlanType];
  const nonParentDetails = userRepresentableDetails(nonParentPlan?.memberDetails as IHealthFamilyDetails, false, t);

  const parentPlan = plans?.[PlanTypes.PARENT];
  const parentsDetails = userRepresentableDetails(parentPlan?.memberDetails as IHealthFamilyDetails, true, t);

    const location = useLocation();
     // Check if the URL ends with "review-details"
     const isReviewDetailsPage = location.pathname.endsWith("review-details");

  const dispatch = useDispatch();
    const {
      formState: { errors },
      setValue
    } = useForm<IPaymentInitiate>({ mode: "onChange" });
    const [paymentMethod, setPaymentMethod] = useState<IPaymentMethod[]>();
    const [cartDetails, setCartDetails] = useState<ICartItem>();
    const [method, setMethod] = useState<IPaymentMethod>();
    const [error, setError] = useState(false);
    const [checkedPaymentMethod, setCheckedPaymentMethod] = useState<number>(0);
    const getCartDetail = async () => {
      try {
        const {
          data: { data, meta }
        } = await getCartDetailById(cartID as string);
        if (meta?.statusCode === StatusCode.SUCCESS) {
          setCartDetails(data);
        } else if (meta?.statusCode === StatusCode.NOT_FOUND) {
          // navigate("/health");
          navigate(`/health-cart/${cartID}/invoice`);
        } else {
          navigate(`/health-cart/${cartID}/invoice`);
        }
      } catch (err) {
        console.warn(err);
      }
    };
    const getMethod = async () => {
      try {
        const {
          data: { data }
        } = await getPaymentMethod();
        console.log("datssssssssa", data);
        const filteredPaymentMethod = data.filter((paymentMethod: { name: string }) => paymentMethod.name === "Online Payment / IBFT");
        setPaymentMethod(filteredPaymentMethod);
        // onSubmit2({} as IPaymentInitiate, filteredPaymentMethod[0] as IPaymentMethod);
      } catch {
        console.warn("error");
      }
    };
    const navigate = useNavigate();
    const { quotationID, cartID } = useParams();
    const [sortedDocuments, setSortedDocuments] = useState<IReviewDetailsResponse[][]>([]);
  
    const sortPlanDocuments = (plans: ICartDetail[]) => {
      const documents: ICartDetailInterface[][] = [];
      plans?.map(plan => documents.push(plan.documents));
      const sortedDocuments = sortRequiredDocumentReviewDetails(documents);
      setSortedDocuments(sortedDocuments as IReviewDetailsResponse[][]);
    };
  
    const groupDocumentsByRelationship = (items: IReviewDetailsResponse[]): IReviewDocuments[] => {
      const groupedDocumentsMap = new Map<number, IReviewDocuments>();
      if (items?.length) {
        for (const item of items ?? []) {
          const { id, fileName, path, relation } = item;
  
          if (!groupedDocumentsMap.has(relation.id)) {
            groupedDocumentsMap.set(relation.id, {
              category: relation.key.charAt(0).toUpperCase() + relation.key.slice(1),
              documents: []
            });
          }
  
          groupedDocumentsMap.get(relation.id)?.documents.push({ id, fileName, path });
        }
      }
  
      const sortedGroupedDocument = sortDocument(Array.from(groupedDocumentsMap.values()));
      return sortedGroupedDocument;
    };
  
    useEffect(() => {
      if (cart) {
        sortPlanDocuments(cart.cartPlans);
      }
    }, [cart]);
  
    const onSubmit2 = async (data: IPaymentInitiate, filteredPaymentMethod: IPaymentMethod) => {
      console.log("asdfasdfasdf", filteredPaymentMethod);
      setValue("paymentPickupAddress", cartDetails?.information.personalDetails.permanentAddress as string);
  
      const payload = {
        cartId: cartID,
        policyDeliveryAddress:  cartDetails?.information.personalDetails.permanentAddress,
        paymentPickupAddress:  cartDetails?.information.personalDetails.permanentAddress,
        paymentGatewayId: selectedPayment === "baadmay" ? "b2bc0037-d87a-4b24-94e9-0d7f353bb1ce" : method?.paymentGatewayId,
        paymentMethodId: selectedPayment === "baadmay" ? 20 : method?.id,
        returnUrl: `${Config.frontEndOrigin}/cart/${cartID}/invoice`
      } as IPaymentInitiate;
      console.log("payload is :", payload);
      try {
        const {
          data: { data, meta }
        } = await createOrder(payload);
        if (meta.statusCode == StatusCode.CREATED) {
          if (data.redirectionRequired) {
            window.location.assign(data.redirectionUrl);
            dispatch(ResetHealthCart());
          } else {
            window.location.assign(data.returnUrl);
          }
        } else if (meta?.statusCode === StatusCode.NOT_FOUND) {
          // navigate("/health");
          navigate(`/cart/${cartID}/invoice`);
        } else {
          navigate(`/cart/${cartID}/invoice`);
        }
      } catch (err: unknown) {
        console.warn(err);
      }
    };
    useEffect(() => {
      setMethod(paymentMethod?.[0]);
      setCheckedPaymentMethod(0);
    }, [paymentMethod]);
    // useEffect(() => {
    //   // getCartDetail();
    //   getMethod();
    // }, []);
    useEffect(() => {
      if (isReviewDetailsPage) {
        getCartDetail();
      }
      getMethod();
    }, [isReviewDetailsPage]);
    
    const currentPayable = useSelector(getCurrentPayable);
    const isBaadmaySelected = selectedPayment === "baadmay";
  
    const [openBreakup] = useState(false);

  return (
    <>
      {nonParentPlan || parentPlan ? (
        <div className="cmw-listing">
          {nonParentDetails?.value && nonParentPlan && (
            <div className="cmw-item">
              <div className="fs-12px fw-medium">{t("health_plan_cart_plan_selected_for")}</div>
              <div>{nonParentDetails.value}</div>
              <PlanCard
                data={nonParentPlan}
                rateModeType={rateModeType}
                onRemove={() => onCartRemove(nonParentPlan.plan.id as number, nonParentPlan.cartID.toString(), nonParentDetails.planType)}
              />
            </div>
          )}

          {parentsDetails.value && parentPlan && (
            <div className="cmw-item">
              <div className="fs-12px fw-medium">{t("health_plan_cart_plan_selected_for")}</div>
              <div>{parentsDetails.value}</div>
              <PlanCard
                data={parentPlan}
                rateModeType={rateModeType}
                onRemove={() => onCartRemove(parentPlan.plan.id as number, parentPlan.cartID.toString(), parentsDetails.planType)}
              />
            </div>
          )}
          {isReviewDetailsPage && (
                      <>
                        <div className="cmw-item">
                          <h3>Payment Method</h3>
                          <p>Select your payment method</p>
                          <div className="form-check customFormBox">
                          <input
                            type="radio"
                            id="baadmay"
                            name="paymentMethod"
                            value="baadmay"
                            className="form-check-input"
                            onChange={handlePaymentChange}
                          />
                              <label htmlFor="baadmay" className="form-check-label">
                              <div className="logo-wrapper d-flex align-items-center mb-3">
                      <a className="baadmay-modal" data-vbtype="inline" data-overlayclose="false" data-maxwidth="620px" href="#baadmay-content">
                          <svg width="116" height="36" viewBox="0 0 116 36" fill="none" xmlns="http://www.w3.org/2000/svg" className="baadmaysvg">
                              <g clip-path="url(#clip0_1_19)">
                                  <path d="M110.957 0H5.04348C2.25804 0 0 2.25804 0 5.04348V30.2609C0 33.0463 2.25804 35.3043 5.04348 35.3043H110.957C113.742 35.3043 116 33.0463 116 30.2609V5.04348C116 2.25804 113.742 0 110.957 0Z" fill="#690EF5" />
                                  <path d="M17.9335 12.5334C19.2758 13.1042 20.3455 14.1654 20.9208 15.497C21.2097 16.1855 21.3584 16.9239 21.3584 17.6697C21.3584 18.4155 21.2097 19.1539 20.9208 19.8424C20.6395 20.5107 20.2333 21.1201 19.7235 21.6384C19.2151 22.1528 18.61 22.5634 17.9423 22.8465C17.2481 23.1394 16.5006 23.2873 15.7462 23.2811C14.996 23.2854 14.2526 23.1375 13.562 22.8465C12.8851 22.5643 12.2702 22.154 11.7515 21.6384C11.2313 21.1237 10.815 20.5151 10.525 19.8453C10.2323 19.1599 10.0833 18.4227 10.087 17.6784V7.61447H12.3209V12.0989H15.7375C16.4918 12.0926 17.2394 12.2405 17.9335 12.5334ZM15.7375 21.0648C16.1898 21.0677 16.6379 20.979 17.0545 20.8042C17.6741 20.5452 18.2027 20.1104 18.5736 19.5542C18.9447 18.9979 19.1415 18.3453 19.1395 17.6784C19.1426 17.2288 19.0532 16.7832 18.8767 16.369C18.5305 15.5619 17.8827 14.9192 17.0691 14.5758C16.6525 14.4009 16.2044 14.3123 15.7522 14.315H12.3209V17.6784C12.3182 18.1311 12.4086 18.5797 12.5867 18.9965C12.8454 19.6127 13.2833 20.1386 13.8445 20.5069C14.4056 20.8753 15.0646 21.0696 15.7375 21.0648Z" fill="white" />
                                  <path d="M28.1659 12.0522C28.9206 12.0478 29.6681 12.1977 30.3619 12.4926C31.7032 13.062 32.7726 14.1209 33.3492 15.4503C33.6448 16.1399 33.7938 16.8824 33.7873 17.6317V23.2344H28.1659C27.4147 23.2383 26.6704 23.0906 25.9787 22.7998C24.6153 22.2276 23.5302 21.1511 22.9534 19.7986C22.6646 19.1101 22.5159 18.3716 22.5159 17.6259C22.5159 16.8801 22.6646 16.1418 22.9534 15.4532C23.2411 14.792 23.6545 14.1921 24.1711 13.6861C24.6924 13.1786 25.3057 12.7736 25.9787 12.4926C26.67 12.1999 27.4142 12.05 28.1659 12.0522ZM31.5533 21.0327V17.6317C31.5564 17.182 31.467 16.7365 31.2905 16.3223C30.9485 15.5103 30.2997 14.8636 29.4829 14.5204C29.0663 14.3455 28.6182 14.2569 28.1659 14.2597C27.2587 14.2554 26.387 14.6087 25.7422 15.2418C25.4334 15.5506 25.1857 15.9142 25.0121 16.3136C24.8356 16.7278 24.7462 17.1733 24.7494 17.623C24.747 18.0686 24.8338 18.5103 25.0047 18.9224C25.1757 19.3344 25.4272 19.7089 25.7451 20.0239C26.0628 20.3388 26.4403 20.5881 26.8559 20.7573C27.2715 20.9265 27.7167 21.0123 28.1659 21.0095L31.5533 21.0327Z" fill="white" />
                                  <path d="M40.5675 12.0522C41.3222 12.0478 42.0698 12.1977 42.7635 12.4926C44.1074 13.0611 45.1777 14.1229 45.7508 15.4561C46.0487 16.1451 46.1989 16.888 46.1917 17.6375V23.2344H40.5675C39.8172 23.2383 39.074 23.0906 38.3833 22.7998C37.7083 22.5155 37.0948 22.1054 36.5757 21.5918C36.0567 21.0778 35.6431 20.4688 35.3579 19.7986C35.0673 19.1105 34.9178 18.372 34.9178 17.6259C34.9178 16.8799 35.0673 16.1413 35.3579 15.4532C35.6434 14.7908 36.0571 14.1905 36.5757 13.6861C37.0963 13.178 37.7097 12.7729 38.3833 12.4926C39.0736 12.1999 39.8167 12.05 40.5675 12.0522ZM43.9578 21.0327V17.6317C43.96 17.1819 43.8696 16.7363 43.692 16.3223C43.5204 15.9225 43.2737 15.5588 42.965 15.2505C42.6552 14.9429 42.2883 14.698 41.8844 14.5291C41.468 14.354 41.0199 14.2652 40.5675 14.2684C39.6603 14.2641 38.7885 14.6174 38.1438 15.2505C37.835 15.5588 37.5883 15.9225 37.4167 16.3223C37.2402 16.7365 37.1506 17.182 37.1538 17.6317C37.1515 18.0841 37.2409 18.5324 37.4167 18.9499C37.5855 19.352 37.8326 19.7171 38.1438 20.0246C38.4623 20.3392 38.8404 20.5882 39.2564 20.7573C39.6724 20.9263 40.118 21.012 40.5675 21.0095L43.9578 21.0327Z" fill="white" />
                                  <path d="M58.6444 7.56522V17.6291C58.6482 18.3734 58.4992 19.1107 58.2064 19.796C57.9222 20.4639 57.5129 21.0724 57.0004 21.5892C56.4866 22.1038 55.8766 22.5142 55.2045 22.7973C54.5109 23.0856 53.7664 23.2342 53.0144 23.2342C52.2623 23.2342 51.5179 23.0856 50.8243 22.7973C49.4609 22.225 48.3758 21.1486 47.7989 19.796C47.5101 19.1075 47.3614 18.3691 47.3614 17.6233C47.3614 16.8776 47.5101 16.1391 47.7989 15.4507C48.0867 14.7895 48.5001 14.1894 49.0167 13.6835C49.5413 13.1748 50.1586 12.7698 50.8359 12.49C51.5267 12.1996 52.27 12.0517 53.0202 12.0555H56.4105V7.56522H58.6444ZM53.0144 21.0157C53.4677 21.0188 53.9168 20.9301 54.3343 20.7549C54.737 20.5827 55.1034 20.3371 55.4147 20.0307C56.0522 19.3918 56.4081 18.5281 56.4046 17.6291V14.2659H53.0144C52.5583 14.2635 52.1064 14.3521 51.6857 14.5265C51.2772 14.693 50.906 14.9383 50.5935 15.2478C50.2847 15.5567 50.0371 15.9202 49.8635 16.3197C49.687 16.7339 49.5976 17.1795 49.6007 17.6291C49.5983 18.0744 49.6851 18.5159 49.8558 18.9278C50.0265 19.3397 50.2779 19.7139 50.5954 20.0289C50.9128 20.3438 51.29 20.5932 51.7052 20.7625C52.1204 20.9319 52.5654 21.0179 53.0144 21.0157Z" fill="white" />
                                  <path d="M74.5586 12.0499C75.3139 12.0433 76.0625 12.1912 76.7574 12.4844C77.4256 12.7681 78.0338 13.1739 78.5505 13.6808C79.069 14.1852 79.4828 14.7856 79.7682 15.4479C80.0661 16.1369 80.2163 16.8798 80.2091 17.6294V23.232H77.9752V17.6294C77.9789 17.1794 77.8884 16.7335 77.7095 16.3199C77.5378 15.9201 77.291 15.5564 76.9824 15.2481C76.3376 14.615 75.4658 14.2618 74.5586 14.266C74.1062 14.2628 73.6581 14.3516 73.2416 14.5267C72.4265 14.8679 71.7779 15.5113 71.4341 16.3199C71.2575 16.7341 71.168 17.1797 71.1712 17.6294V23.232H68.9344V17.6294C68.9375 17.1797 68.8481 16.7341 68.6716 16.3199C68.4999 15.9201 68.2531 15.5564 67.9445 15.2481C67.6323 14.9359 67.2612 14.6879 66.8523 14.518C66.4304 14.3437 65.9776 14.255 65.5207 14.2573C65.0684 14.2541 64.6202 14.3429 64.2037 14.518C63.7999 14.6869 63.433 14.9319 63.1233 15.2394C62.8145 15.5477 62.5677 15.9114 62.3961 16.3112C62.2186 16.7252 62.1282 17.1708 62.1304 17.6207V23.232H59.8965V17.6294C59.8893 16.8798 60.0395 16.1369 60.3374 15.4479C60.9117 14.1129 61.9874 13.0521 63.3364 12.4902C64.0272 12.1995 64.7704 12.0516 65.5207 12.0557C66.4157 12.0454 67.2993 12.2544 68.0934 12.664C68.8614 13.062 69.5275 13.6287 70.0412 14.3211C70.5559 13.6266 71.2255 13.0595 71.9977 12.664C72.7882 12.2557 73.6673 12.0449 74.5586 12.0499Z" fill="white" />
                                  <path d="M87.1422 12.0499C87.8966 12.0436 88.6441 12.1915 89.3383 12.4844C90.6821 13.0529 91.7525 14.1148 92.3255 15.4479C92.6235 16.1369 92.7737 16.8798 92.7665 17.6294V23.232H87.1422C86.392 23.2361 85.6487 23.0882 84.958 22.7974C84.2831 22.5132 83.6695 22.103 83.1504 21.5895C82.6283 21.0762 82.2117 20.4672 81.9239 19.7963C81.6334 19.1081 81.4837 18.3696 81.4837 17.6235C81.4837 16.8775 81.6334 16.139 81.9239 15.4508C82.2093 14.7885 82.623 14.1881 83.1416 13.6837C83.6649 13.1747 84.2814 12.7696 84.958 12.4902C85.6484 12.1975 86.3915 12.0477 87.1422 12.0499ZM90.5326 21.0303V17.6294C90.5347 17.1795 90.4443 16.7339 90.2668 16.3199C90.0952 15.9201 89.8484 15.5564 89.5398 15.2481C89.23 14.9405 88.8631 14.6956 88.4592 14.5267C88.0427 14.3516 87.5946 14.2628 87.1422 14.266C86.235 14.2617 85.3633 14.615 84.7186 15.2481C84.4098 15.5564 84.1631 15.9201 83.9914 16.3199C83.8148 16.7341 83.7254 17.1797 83.7286 17.6294C83.7263 18.0818 83.8157 18.53 83.9914 18.9475C84.1603 19.3496 84.4074 19.7148 84.7186 20.0222C85.0371 20.3368 85.4152 20.5858 85.8312 20.7549C86.2471 20.924 86.6927 21.0096 87.1422 21.0072L90.5326 21.0303Z" fill="white" />
                                  <path d="M105.192 12.0718V22.1357C105.199 22.8844 105.049 23.6263 104.751 24.3142C104.466 24.9798 104.058 25.587 103.548 26.1045C103.042 26.6133 102.441 27.0204 101.779 27.3039C101.085 27.5968 100.337 27.7447 99.5827 27.7384H98.4526V25.5252H99.5827C100.252 25.5266 100.906 25.3307 101.462 24.9624C102.019 24.5941 102.452 24.07 102.707 23.4567C102.877 23.0371 102.958 22.5876 102.947 22.1357V22.1097C102.475 22.477 101.944 22.763 101.376 22.9555C100.078 23.3932 98.6631 23.3446 97.3985 22.8195C96.0296 22.2503 94.9389 21.1734 94.3586 19.8182C94.0633 19.1336 93.9132 18.396 93.9176 17.6513V12.0718H96.1545V17.6513C96.1514 18.0969 96.2375 18.5387 96.408 18.9509C96.5785 19.3632 96.8297 19.7378 97.1474 20.0527C97.465 20.3678 97.8426 20.6172 98.2582 20.7863C98.6738 20.9554 99.119 21.0409 99.5682 21.0379C100.021 21.041 100.469 20.9522 100.885 20.7771C101.5 20.5141 102.023 20.0775 102.39 19.5217C102.756 18.9659 102.95 18.3155 102.947 17.6513V12.0718H105.192Z" fill="white" />
                              </g>
                              <defs>
                                  <clipPath id="clip0_1_19">
                                      <rect width="116" height="35.3043" fill="white" />
                                  </clipPath>
                              </defs>
                          </svg>
                      </a>
                      <p className="ps-3 mb-0">Pay in 3 Installments of Rs. {((parseFloat(currentPayable.replace(/[^\d.-]/g, '')) * 1.5) / 3).toFixed(0)}</p>
                  </div>
                  <p className="baadmayCustomContent pt-3">After clicking “Pay now”, you will be redirected to BaadMay | Buy Now. Pay Later to complete your purchase securely.</p>
                              </label>
                            </div>
          
                            <div className="form-check customFormBox mt-3">
                            <input
                              type="radio"
                              id="otherPayment"
                              name="paymentMethod"
                              value="otherPayment"
                              className="form-check-input"
                              onChange={handlePaymentChange}
                              checked={selectedPayment === "otherPayment"}
                            />
                              <label htmlFor="payfast" className="form-check-label">
                                Pay Online
                                <p className="mb-0">Pay now with <span className="fw-bold">Payfast</span></p>
                              </label>
                            </div>
                        </div>
                        <div className="cmw-item d-flex justify-content-between">
                          <div>
                            <div className="fs-12px fw-semibold">{t("health_plan_cart_currently_payable")}</div>
                            <p>
                              {isBaadmaySelected
                                ? `Rs. ${((parseFloat(currentPayable.replace(/[^\d.-]/g, '')) * 1.5) / 3).toFixed(0)}`
                                : currentPayable}
                            </p>
                          </div>
                          <Button
                            size="lg"
                            className="pww-btn"
                            onClick={() => {
                              onSubmit2();
                              onClose();
                            }} 
                          >
                            {t("proceed_to_payment")}
                        </Button>
                        </div>
                      </>
                    )}
        </div>
      ) : (
        <div className="cmw-empty">
          <img
            src="/assets/img/empty-cart.svg"
            alt="empty cart"
          />
          {t("empty_cart_body_message")}
          <Button
            size="lg"
            onClick={() => onClose()}
          >
            {t("close")}
          </Button>
        </div>
      )}
    </>
  );
};

export default CartBody;
