export const phoneAndEmailPattern = /^(\+92\d{10}|[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,})$/i;
export const phonePattern = /^\+92\d{10}$/;
export const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const noSpecialCharacter = /^[a-zA-Z0-9\s-]*$/;
export const cnicPattern = /^\d{5}-\d{7}-\d{1}$/;
export const otpPattern = /^\d{4}$/;
export const checkIsPersonalAccident = /^\/.*personal-accident.*\/.*$/;
export const checkIsHouseHold = /^\/.*house-hold.*\/.*$/;
export const checkIsLife = /^\/.*life.*\/.*$/;
export const checkIsAuto = /^\/.*auto.*\/.*$/;
export const checkIsHealth = /^\/.*health.*\/.*$/;
