import { Container } from "react-bootstrap";
import { Facebook, Instagram, Linkedin, Twitter, Whatsapp, Youtube, Telephone } from "../../../../shared/components/icons";
import React, { ReactElement } from "react";
import { navigateToUrl } from "../../../../shared/helper";
import { SocialUrls } from "../../../../shared/constant";
import OurApp from "../../../health/component/home/ourApp";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function FooterComponent(): ReactElement {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      {/* <OurApp /> */}
      <footer className="main-footer">
        <Container className="mf-top">
          <div className="row">
            <div className="col-lg-3">
              <a className="mf-logo">
                <img
                  src="/assets/img/logo-light.svg"
                  alt="Takaful Bazaar"
                  width={200}
                  height={66}
                />
              </a>
              <div className="mf-social">
                <a
                  href={SocialUrls.FACEBOOK}
                  target={"_blank"}
                  className="mf-s-item"
                  rel="noreferrer"
                >
                  <Facebook />
                </a>
                <a
                  className="mf-s-item"
                  href={SocialUrls.INSTAGRAM}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <Instagram />
                </a>
                <a
                  className="mf-s-item"
                  href={SocialUrls.LINKEDIN}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <Linkedin />
                </a>
                <a
                  className="mf-s-item"
                  href={SocialUrls.TWITTER}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <Twitter />
                </a>
                <a
                  className="mf-s-item"
                  href={SocialUrls.WHATSAPP}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <Whatsapp />
                </a>
                <a
                  className="mf-s-item"
                  href={SocialUrls.YOUTUBE}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <Youtube />
                </a>
              </div>
              <div className="mf-uan">
                <Telephone />
                <span>
                  {t("UAN_number")}
                  <hr></hr>
                  {t("PTCL_number")}
                </span>
              </div>
            </div>
            <div className="col-lg-4 col-xl-4 col-xxl-4">
              <div className="border-top border-secondary my-4 d-md-block d-lg-none"></div>
              <h4 className="mf-title">{`${t("header_&_footer_takaful")} ${t("footer_bazaar")}`}</h4>
              <div className="row">
                <div className="col-6 col-lg-5 col-xxl-6">
                  <a
                    className="mf-link"
                    onClick={() => navigateToUrl("/")}
                  >
                    {t("footer_home")}
                  </a>
                </div>
                <div className="col-6 col-lg-7 col-xxl-6">
                  <a
                    className="mf-link"
                    onClick={() => navigateToUrl("/about-us")}
                  >
                    {t("header_&_footer_about_us")}
                  </a>
                </div>
                {/* <div className="col-6 col-lg-5 col-xxl-6">
                  <a
                    className="mf-link"
                    onClick={() => navigateToUrl("/faq")}
                  >
                    {t("header_&_footer_faqs")}
                  </a>
                </div> */}
                {/* <div className="col-6 col-lg-7 col-xxl-6">
                  <a
                    className="mf-link"
                    onClick={() => navigateToUrl("/")}
                  >
                    {t("footer_sitemap")}
                  </a>
                </div> */}
                <div className="col-6 col-lg-7 col-xxl-6">
                  <a
                    className="mf-link"
                    onClick={() => navigate("/terms-and-conditions")}
                  >
                    {t("footer_terms_and_condition")}
                  </a>
                </div>
                <div className="col-6 col-lg-5 col-xxl-6">
                  <a
                    className="mf-link"
                    onClick={() => navigate("/privacy-policy")}
                  >
                    {t("footer_privacy_policy")}
                  </a>
                </div>
                <div className="col-6 col-lg-5 col-xxl-6">
                  <a
                    className="mf-link"
                    onClick={() => navigate("/contact-us")}
                  >
                    {t("header_&_footer_contact_us")}
                  </a>
                </div>
                <div className="col-6 col-lg-7 col-xxl-6">
                  <a
                    className="mf-link"
                    onClick={() => navigate("/certificate")}
                  >
                    {t("footer_shariah_certificates")}
                  </a>
                </div>
                
              </div>
            </div>
            <div className="col-lg-2 col-xxl-2 col-xl-2">
              <div className="border-top border-secondary my-4 d-md-block d-lg-none"></div>
              <h4 className="mf-title">{t("header_&_footer_products")}</h4>
              <div className="row">
                <div className="col-6 col-lg-12">
                  <a
                    className="mf-link"
                    onClick={() => navigate("/auto")}
                  >
                    {`${t("customer_policy_auto_type_label")} ${t("header_&_footer_takaful")}`}
                  </a>
                </div>
                <div className="col-6 col-lg-12">
                  <a
                    className="mf-link"
                    onClick={() => navigate("/travel")}
                  >
                    {`${t("customer_policy_travel_type_label")} ${t("header_&_footer_takaful")}`}
                  </a>
                </div>
                <div className="col-6 col-lg-12">
                  <a
                    className="mf-link"
                    onClick={() => navigate("/health")}
                  >
                    {`${t("customer_policy_health_type_label")} ${t("header_&_footer_takaful")}`}
                  </a>
                </div>
                <div className="col-6 col-lg-12">
                  <a
                    className="mf-link"
                    onClick={() => navigate("/personal-accident")}
                  >
                    {`${t("customer_policy_family_type_label")} ${t("header_&_footer_takaful")}`}
                  </a>
                </div>
                <div className="col-6 col-lg-12">
                  <a
                    className="mf-link"
                    onClick={() => navigate("/sme")}
                  >
                    Group Health Takaful
                  </a>
                </div>
                {/* <div className="col-6 col-lg-12">
                  <a
                    className="mf-link"
                    onClick={() => navigate("/sehatzamin")}
                  >
                    {`${t("customer_policy_sehat_zamin_type_label")} ${t("header_&_footer_takaful")}`}
                  </a>
                </div> */}
                <div className="col-6 col-lg-12">
                  <a
                    className="mf-link"
                    onClick={() => navigate("/house-hold")}
                  >
                    {`${t("customer_policy_house_hold_type_label")} ${t("header_&_footer_takaful")}`}
                  </a>
                </div>
                {/* <div className="col-6 col-lg-12">
                  <a
                    className="mf-link"
                    onClick={() => navigate("/life")}
                  >
                    {`${t("customer_policy_life_type_label")} ${t("header_&_footer_takaful")}`}
                  </a>
                </div> */}
              </div>
            </div>
            {/* <div className="col-lg col-xl-2">
              <div className="border-top border-secondary my-4 d-md-block d-lg-none"></div>
              <h4 className="mf-title">{t("header_&_footer_claims")}</h4>
              <div className="row">
                <div className="col-6 col-lg-12">
                  <a
                    className="mf-link"
                    onClick={() => navigateToUrl("/auto/claims")}
                  >
                    {`${t("customer_policy_auto_type_label")} ${t("header_&_footer_takaful")} ${t("footer_claim")}`}
                  </a>
                </div>
                <div className="col-6 col-lg-12">
                  <a
                    className="mf-link"
                    onClick={() => navigateToUrl("/travel")}
                  >
                    {`${t("customer_policy_travel_type_label")} ${t("header_&_footer_takaful")} ${t("footer_claim")}`}
                  </a>
                </div>
                <div className="col-6 col-lg-12">
                  <a
                    className="mf-link"
                    onClick={() => navigate("/health")}
                  >
                    {`${t("customer_policy_health_type_label")} ${t("header_&_footer_takaful")} ${t("footer_claim")}`}
                  </a>
                </div>
              </div>
            </div> */}
            <span className="d-md-none d-block fontAddress-footer">{t("footer_office_address")}</span>
          </div>
        </Container>
        <div className="mf-mid">
          <Container>
            <section className="mf-association">
              <h4 className="mf-title alt">{t("customer_policy_payment_method_label")}</h4>
              <div className="mf-a-inner">
                <a className="mf-a-item">
                  <img
                    src="/assets/img/bank-alfalah.png"
                    alt="Bank Alfalah"
                    height={30}
                    width={100}
                  />
                </a>
                <a className="mf-a-item">
                  <img
                    src="/assets/img/payfastIcon.png"
                    alt="payfastIcon"
                    height={30}
                    width={100}
                  />
                </a>
                {/* <a className="mf-a-item">
                  <img
                    src="/assets/img/visa.png"
                    alt="Visa"
                    height={30}
                    width={100}
                  />
                </a>
                <a className="mf-a-item">
                  <img
                    src="/assets/img/master-card.png"
                    height={30}
                    width={100}
                    alt="Mastercard"
                  />
                </a> */}
              </div>
            </section>
            <section className="mf-association">
              <h4 className="mf-title alt text-align">{t("footer_secured_by")}</h4>
              <div className="mf-a-inner">
                <a className="mf-a-item">
                  <img
                    width={100}
                    height={30}
                    src="/assets/img/ssl-secure.png"
                    alt="SSL Secure"
                  />
                </a>
                <a className="mf-a-item">
                  <img
                    src="/assets/img/norton.png"
                    alt="Norton"
                    width={100}
                    height={30}
                  />
                </a>
              </div>
            </section>
          </Container>
        </div>
        <Container className="mf-copyright">
          <span className="d-none d-md-block">{t("footer_office_address")}</span>
          <span>
            {" "}
            &copy;
            {t("footer_copy_right_content")}
          </span>
        </Container>
      </footer>
    </>
  );
}
