/* eslint-disable import/no-unused-modules */
import { RootState } from "../../../store";
import { IAutoCartPlans } from "../../../../modules/auto/utils/interface";
// import { IAutoCartPlans } from "@/modules/health/utils/interfaces/index.js";
import { createSlice } from "@reduxjs/toolkit";

interface IAutoCart {
  plans: IAutoCartPlans;
  totalContribution: string;
  currentPayable: string;
  isCartbarOpen: boolean;
  rateModeType: string;
}

const initialState: IAutoCart = {
  plans: {},
  totalContribution: "",
  currentPayable: "",
  rateModeType: "",
  isCartbarOpen: false
};

// eslint-disable-next-line import/no-unused-modules
export const autoCart = createSlice({
  name: "autoCart",
  initialState,
  reducers: {
    AddPlanInCart: (state, { payload }) => {
      state.plans[payload.planType] = payload.planDetails;
      state.totalContribution = payload.totalContribution;
      state.currentPayable = payload.currentPayable;
      state.rateModeType = payload.rateModeType;
    },
    RemovePlanCart: (state, { payload }) => {
      const addedPlanTypes = Object.keys(state.plans);

      const newCart: IAutoCartPlans = {};
      addedPlanTypes.forEach(planType => {
        const isRemovingPlan = planType === payload;
        if (!isRemovingPlan) {
          newCart[planType as keyof typeof newCart] = state.plans[planType];
        }
      });

      state.plans = newCart;
    },
    updateCartBarStatus: (state, { payload }) => {
      state.isCartbarOpen = payload;
    },
    clearCartPlans: state => {
      state.plans = {};
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars, no-param-reassign
    ResetAutoCart: state => (state = initialState)
  }
});

export const { AddPlanInCart, RemovePlanCart, updateCartBarStatus, clearCartPlans, ResetAutoCart } = autoCart.actions;

export const getCartPlans = (state: RootState): IAutoCartPlans => state.autoCart.plans;

export const getRateModeType = (state: RootState): string => state.autoCart.rateModeType;

export const getCurrentPayable = (state: RootState): string => state.autoCart.currentPayable;

export const getCartBarStatus = (state: RootState): boolean => state.autoCart.isCartbarOpen;

export default autoCart.reducer;
