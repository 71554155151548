import Api from "../../../../shared/network/axiosClients";
import { IAlphaNumeric } from "../../../../shared/interface";
import { AxiosResponse } from "axios";
import { IMemberDetails, IPaymentInitiate, IPersonalDetails, IUnsavedData } from "../../utils/interfaces";
import { IChildDetails } from "../../utils/interfaces/order-details";

export const getQuoteDetailByQuoteId = async (id: IAlphaNumeric): Promise<AxiosResponse> => {
  return Api.get(`web/quotation/health/${id}`);
};
export const getCartOrdersByID = (cartID: string): Promise<AxiosResponse> => {
  return Api.get(`cart/${cartID}/orders`);
};

export const getPaymentMethod = async (): Promise<AxiosResponse> => {
  return Api.get("web/payments/methods");
};
export const createOrder = async (data: IPaymentInitiate): Promise<AxiosResponse> => {
  return Api.post("web/health-order", data);
};
export const getCartDetailById = async (id: IAlphaNumeric): Promise<AxiosResponse> => {
  return Api.get(`cart/${id}`);
};
export const getRequiredDocuments = async (id: IAlphaNumeric[]): Promise<AxiosResponse> => {
  return Api.get(`web/health/shelf-plans/required-documents?${id.map((ids: IAlphaNumeric) => `healthPlanIds=${ids}`).join("&")}`);
};
export const createPersonalDetails = async (id: string, data: IPersonalDetails): Promise<AxiosResponse> => {
  return Api.put(`cart/${id}/personalDetails`, data);
};

export const createMemberDetails = async (id: string, data: IMemberDetails): Promise<AxiosResponse> => {
  return Api.put(`cart/${id}/memberDetails`, data);
};

export const createChildDetails = async (id: string, data: IChildDetails): Promise<AxiosResponse> => {
  return Api.put(`cart/${id}/childDetails`, data);
};

export const createMemberDocument = async (id: string, data: Partial<IPersonalDetails> | Partial<IPersonalDetails>[]): Promise<AxiosResponse> => {
  return Api.put(`cart/${id}/documentDetails`, data);
};

export const postUnSavedData = async (data: IUnsavedData, cartID: string): Promise<AxiosResponse> => {
  return Api.put(`cart/${cartID}/unSavedData`, data);
};

export const getCartByCartId = async (id: IAlphaNumeric): Promise<AxiosResponse> => {
  return Api.get(`cart/${id}`);
};
export const reviewCartDetails = async (id: IAlphaNumeric): Promise<AxiosResponse> => {
  return Api.put(`cart/${id}/reviewDetails`);
};
