import Api from "../../../../shared/network/axiosClients";
import { IAlphaNumeric } from "../../../../shared/interface";
import { AxiosResponse } from "axios";
import { getAPIUrl } from "../../../../shared/helper";

export const getQuoteDetailByQuoteId = async (id: IAlphaNumeric): Promise<AxiosResponse> => {
  return Api.get(`web/quotation/auto/${id}`);
};

export const getAllPlans = async (body: { quotationId: string }): Promise<AxiosResponse> => {
  const { contactNumber, email, name, policy , ...filteredBody } = body;
  return Api.post(getAPIUrl("web/auto/plans?take=100"), filteredBody, {});
};

// export const getQuoteDetailByQuoteId = async (id: IAlphaNumeric): Promise<AxiosResponse> => {
//   return Api.get(`web/quotation/health/${id}`);
// };

export const getMonthlyAgreeablePriceByAnnualContibution = (
  quotationId: string,
  planId: number,
  contribution: number,
  currencyCode: string
): Promise<AxiosResponse> => {
  return Api.get(
    getAPIUrl("cart/calculate-monthly-contribution", {
      quotationId,
      planId,
      contribution,
      currencyCode
    })
  );
};
