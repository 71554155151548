import React, { ReactElement, useEffect, useState } from "react";
import Header from "./header";
import Footer from "./footer";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import ScheduleCall from "../../component/scheduleCall";

const Layout = (): ReactElement => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  useEffect(() => {
    redirectToHealth();
  }, []);

  const redirectToHealth = () => {
    const currentPath = pathname;
    if (currentPath === "/") {
  //     /**
  //      *
  //      * Whenever want to use static pages in application we have to assign path in
  //      * window.location.href instead of calling react useNavigate like this
  //      *
  //      *
  //      * window.location.href = `${window.location.href}health`
  //      */
      // navigate("/health");
    }
  };

  return (
    <div>
      <Header />
      {search && <ScheduleCall />}
      <Outlet />
      <Footer />
    </div>
  );
};

export default Layout;
