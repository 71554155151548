import React, { lazy } from "react";

const Travel = lazy(() => import("../../modules/travel/container/home"));
const TravelPlans = lazy(() => import("../../modules/travel/container/planListing"));

const TravelPlansDetail = lazy(() => import("../../modules/travel/container/planDetails"));
const TravelPlansCompare = lazy(() => import("../../modules/travel/container/compare"));

const TravelOrderDetailsDocumentUpload = lazy(() => import("../../modules/travel/container/orderDetails/document-upload"));
const TravelOrderDetailsPaymentDetails = lazy(() => import("../../modules/travel/container/orderDetails/payment-details"));
const TravelOrderDetailsPersonalDetails = lazy(() => import("../../modules/travel/container/orderDetails/personal-details"));
const TravelOrderDetailsReviewDetails = lazy(() => import("../../modules/travel/container/orderDetails/review-details"));

export const travelRoutes = [
  {
    path: "/travel",
    element: <Travel />
  },
  {
    path: "/travel/plans/:quotationID",
    element: <TravelPlans />
  },
  {
    path: "/travel/plans/:quotationID/detail/:id",
    element: <TravelPlansDetail />
  },
  {
    path: "/travel/plans/:quotationID/compare/:id",
    element: <TravelPlansCompare />
  },
  {
    path: "/travel/order-details/:quotationID/:cartID/document-upload",
    element: <TravelOrderDetailsDocumentUpload />
  },
  {
    path: "/travel/order-details/:quotationID/:cartID/payment-details",
    element: <TravelOrderDetailsPaymentDetails />
  },
  {
    path: "/travel/order-details/:quotationID/:cartID/personal-details",
    element: <TravelOrderDetailsPersonalDetails />
  },
  {
    path: "/travel/order-details/:quotationID/:cartID/review-details",
    element: <TravelOrderDetailsReviewDetails />
  }
];
