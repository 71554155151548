import { combineReducers } from "redux";
import counterReducer from "./features/counter/counterSlice";
import userDetailsReducer, { userDetails } from "./features/health/userDetails";
// eslint-disable-next-line import/no-named-as-default
import HealthOrderDetails from "./features/health/order/index";
import userDetailsAutoReducer from "./features/auto/userDetails";
import authReducer from "./features/auth";
import compareReducer from "./features/health/compare";
import healthCartReducer from "./features/health/cart";
import autoCartReducer from "./features/auto/cart";
import personalAccidentCartReducer from "./features/personalAccident/cart";
import personalAccidentOrderReducer from "./features/personalAccident/order";
import userDetailsPersonalAccidentReducer from "./features/personalAccident/userDetails";
import houseHoldCartReducer from "./features/houseHold/cart";
import houseHoldOrderReducer from "./features/houseHold/order";
import userDetailsHouseHoldReducer from "./features/houseHold/userDetails";
import lifeCartReducer from "./features/life/cart";
import lifeOrderReducer from "./features/life/order";
import userDetailsLifeReducer from "./features/life/userDetails";

import loadingReducer from "./features/loader";

export default combineReducers({
  auth: authReducer,
  counter: counterReducer,
  user: userDetailsReducer,
  comparePlans: compareReducer,
  healthCart: healthCartReducer,
  loading: loadingReducer,
  HealthOrderDetails: HealthOrderDetails,
  autoCart: autoCartReducer,
  userDetailsAuto: userDetailsAutoReducer,
  personalAccidentCart: personalAccidentCartReducer,
  PersonalAccidentOrderDetails: personalAccidentOrderReducer,
  userDetailsPersonalAccident: userDetailsPersonalAccidentReducer,
  houseHoldCart: houseHoldCartReducer,
  HouseHoldOrderDetails: houseHoldOrderReducer,
  userDetailsHouseHold: userDetailsHouseHoldReducer,
  lifeCart: lifeCartReducer,
  lifeOrderDetails: lifeOrderReducer,
  userDetailsLife: userDetailsLifeReducer
});
