import SolarHome from "../../modules/solar/container/home";
import SMEHome from "../../modules/sme/container/home";
import React, { lazy } from "react";

const TermsAndCondtions = lazy(() => import("../../modules/terms-and-conditions"));
const PrivacyPolicy = lazy(() => import("../../modules/privacy-policy"));
const ContactUs = lazy(() => import("../../modules/contact-us/container/index.tsx"));
const ShariahCertificate = lazy(() => import("../../modules/shariahCertificate/shariahCertificate.tsx"));
const AboutUs = lazy(() => import("../../modules/about-us/container/index.tsx"));
const Home = lazy(() => import("../../modules/home/container/home"));
const Health = lazy(() => import("../../modules/health/container/home"));
const TravelHome = lazy(() => import("../../modules/travel/container/home/index.tsx"));
const SehatHome = lazy(() => import("../../modules/sehat/container/home/heroBanner/index.tsx"));
const HealthPlans = lazy(() => import("../../modules/health/container/planListing"));

const HealthPlansDetail = lazy(() => import("../../modules/health/container/planDetails"));
const HealthPlansCompare = lazy(() => import("../../modules/health/container/compare"));

const HealthOrderDetailsDocumentUpload = lazy(() => import("../../modules/health/container/orderDetails/document-upload"));
const HealthOrderDetailsPaymentDetails = lazy(() => import("../../modules/health/container/orderDetails/payment-details"));
const HealthOrderDetailsPersonalDetails = lazy(() => import("../../modules/health/container/orderDetails/personal-details"));
const HealthOrderDetailsReviewDetails = lazy(() => import("../../modules/health/container/orderDetails/review-details"));

export const healthRoutes = [
  {
    path: "/",
    element: <Home />
  },
  {
    path: "/travel",
    element: <TravelHome />
  },
  {
    path: "/health",
    element: <Health />
  },
  {
    path: "/sehatzamin",
    element: <SehatHome />
  },
  {
    path: "/sme",
    element: <SMEHome />
  },
  {
    path: "/solar",
    element: <SolarHome />
  },
  {
    path: "/terms-and-conditions",
    element: <TermsAndCondtions />
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />
  },
  {
    path: "/contact-us",
    element: <ContactUs />
  },
  {
    path: "/certificate",
    element: <ShariahCertificate />
  },
  {
    path: "/about-us",
    element: <AboutUs />
  },
  {
    path: "health/plans/:quotationID",
    element: <HealthPlans />
  },
  {
    path: "/health/plans/:quotationID/detail/:id",
    element: <HealthPlansDetail />
  },
  {
    path: "/health/plans/:quotationID/compare/:id",
    element: <HealthPlansCompare />
  },
  {
    path: "/health/order-details/:quotationID/:cartID/document-upload",
    element: <HealthOrderDetailsDocumentUpload />
  },
  {
    path: "/health/order-details/:quotationID/:cartID/payment-details",
    element: <HealthOrderDetailsPaymentDetails />
  },
  {
    path: "/health/order-details/:quotationID/:cartID/personal-details",
    element: <HealthOrderDetailsPersonalDetails />
  },
  {
    path: "/health/order-details/:quotationID/:cartID/review-details",
    element: <HealthOrderDetailsReviewDetails />
  }
];
