import { useEffect, useState, useRef } from "react";
import { Container, Row } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";

const whatsNew = [
  {
    title: "Sehat Zamin",
    description:
      "EFU Life offers affordable, exclusive health insurance to keep your family safe",
    href: "https://takafulbazaar.pk/sehatzamin"
  },
  {
    title: "Personal Accident",
    description: "Stay secure from emergencies with our Personal Accident Plan",
    href: "/personal-accident"
  },
  {
    title: "Home",
    description: "Stay secure from emergencies with our Personal Accident Plan",
    href: "/house-hold"
  },
];
const MegaDropdown: React.FC<MegaDropdownProps> = ({ navItems }) => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      offset: 200,    // Offset for triggering animations
    });
  }, []);
  return (
    <div className="megaMenu">
          <Container>
            <Row>
              <div className="col-md-7 borderRightDD">
                <h3 className="pb-4 megaMenuh3"
                data-aos="fade-right" // Apply AOS animation
                >Our Products</h3>
                
                <Row data-aos="fade-right">
                  {navItems.map((item, index) => (
                    <div className="col-md-6 mb-3" key={index}>
                      <div className="d-flex">
                        <img
                          className="imgCustomSize"
                          src={item?.icon}
                          alt="Icon"
                        />
                        <h4 
                        className="pb-0 mb-0 megaMenuh4" 
                        onClick={() => window.location.href = item.namehref}>
                          {item.name}
                        </h4>
                      </div>

                      <ul className="listingMegaMenu mb-0">
                        {item.categories.map((category, catIndex) => (
                          <li key={catIndex}>
                            <a className="listingCats" href={item.catLinks[catIndex]}>{category}</a>
                            {/* Conditionally render an image after Sehat Zamin */}
                            {item.name === "Health" &&
                              category === "Sehat Zamin" && (
                                <img
                                  src="../assets/img/bestValueSZ.png"
                                  alt="Sehat Zamin Visual"
                                  className="healthCategoryImage"
                                />
                              )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </Row>
              </div>
              <div className="col-md-5 ps-4">
                <div className="row" data-aos="fade-left">
                  <h3 className="megaMenuh3">Whats New?</h3>
                  <div className="mt-4">
                    {whatsNew.map((item, index) => (
                      <div
                        className="col-12 mb-3 py-3 px-3 MegaRightBox"
                        key={index}
                        onClick={() => window.location.href = item.href} // Redirect on click
                      >
                        <h5>{item.title}</h5>
                        <p className="mb-0">{item.description}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Row>
          </Container>
        </div>
  );
};

export default MegaDropdown;
