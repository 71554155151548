import React, { lazy } from "react";

const Auto = lazy(() => import("../../modules/auto/container/home"));
const AutoPlansDetail = lazy(() => import("../../modules/auto/container/planDetails"));
const AutoPlansCompare = lazy(() => import("../../modules/auto/container/compare"));
const AutoPlans = lazy(() => import("../../modules/auto/container/planListing"));

const AutoOrderDetailsDocumentUpload = lazy(() => import("../../modules/auto/container/orderDetails/document-upload"));
const AutoOrderDetailsPaymentDetails = lazy(() => import("../../modules/auto/container/orderDetails/payment-details"));
const AutoOrderDetailsPersonalDetails = lazy(() => import("../../modules/auto/container/orderDetails/personal-details"));
const AutoOrderDetailsReviewDetails = lazy(() => import("../../modules/auto/container/orderDetails/review-details"));
const AutoOrderDetailsVehicleDetails = lazy(() => import("../../modules/auto/container/orderDetails/vehicle-details"));

export const AutoRoutes = [
  {
    path: "/auto",
    element: <Auto />
  },
  {
    path: "auto/plans/:quotationID",
    element: <AutoPlans />
  },
  {
    path: "/auto/plans/:quotationID/detail/:id",
    element: <AutoPlansDetail />
  },
  {
    path: "/auto/plans/:quotationID/compare/:id",
    element: <AutoPlansCompare />
  },
  {
    path: "/auto/order-details/:quotationID/:cartID/document-upload",
    element: <AutoOrderDetailsDocumentUpload />
  },
  {
    path: "/auto/order-details/:quotationID/:cartID/payment-details",
    element: <AutoOrderDetailsPaymentDetails />
  },
  {
    path: "/auto/order-details/:quotationID/:cartID/personal-details",
    element: <AutoOrderDetailsPersonalDetails />
  },
  {
    path: "/auto/order-details/:quotationID/:cartID/vehicle-details",
    element: <AutoOrderDetailsVehicleDetails />
  },
  {
    path: "/auto/order-details/:quotationID/:cartID/review-details",
    element: <AutoOrderDetailsReviewDetails />
  }
];
