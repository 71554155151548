import { userRepresentableDetails } from "../../../helper";
import { useTranslation } from "react-i18next";
import React, { ReactElement } from "react";
import { useSelector } from "react-redux";
import PlanCard from "./PlanCard";
import { getCartPlans, getRateModeType } from "../../../../redux/features/auto/cart";
import { PlanTypes } from "../../../constant";
import { Button } from "react-bootstrap";
import { IHealthFamilyDetails } from "../../../../modules/health/utils/interfaces";
import { useLocation } from "react-router-dom";
interface IProps {
  onCartRemove: (planID: number, cartID: string, planType: string) => void;
  onClose: Function;
}

const AutoCartBody = ({ onCartRemove, onClose }: IProps): ReactElement => {
  const { t } = useTranslation();
  const plans = useSelector(getCartPlans);
  console.log("plans2233", plans);

  const rateModeType = useSelector(getRateModeType);

  const nonParentPlanType = Object.keys(plans).filter(planType => planType !== PlanTypes.PARENT)?.[0];

  const nonParentPlan = plans?.[nonParentPlanType];
  const nonParentDetails = userRepresentableDetails(nonParentPlan?.memberDetails as IHealthFamilyDetails, false, t);

  const parentPlan = plans?.[PlanTypes.PARENT];

  const location = useLocation();
   // Check if the URL ends with "review-details"
   const isReviewDetailsPage = location.pathname.endsWith("review-details");

  return (
    <>
      {nonParentPlan || parentPlan ? (
        <div className="cmw-listing">
          {nonParentDetails?.value && nonParentPlan && (
            <div className="cmw-item">
              {/* <div className="fs-12px fw-medium">{t("auto_plan_cart_plan_selected_for")}</div> */}
              {/* <div>{nonParentDetails.value}</div> */}
              <PlanCard
                data={nonParentPlan}
                rateModeType={rateModeType}
                onRemove={() => onCartRemove(nonParentPlan.plan.id as number, nonParentPlan.cartID.toString(), nonParentDetails.planType)}
              />
            </div>
          )}
          {/* {isReviewDetailsPage && (
            <div className="cmw-item">
              <h3>Payment Method</h3>
              <p>Select your payment method</p>
              <div className="product-widget-baadmay">
                <iframe
                  src="/modal/product-widget.html"
                  width="100%"
                  height="100%"
                  title="Product Widget"
                ></iframe>
              </div>
            </div>
          )} */}
        </div>
      ) : (
        <div className="cmw-empty">
          <img
            src="/assets/img/empty-cart.svg"
            alt="empty cart"
          />
          {t("empty_cart_body_message")}
          <Button
            size="lg"
            onClick={() => onClose()}
          >
            {t("close")}
          </Button>
        </div>
      )}
    </>
  );
};

/* eslint-disable import/no-unused-modules */
export default AutoCartBody;
