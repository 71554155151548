import { IHealthAgeDetails, IHealthQuotation } from "../../../../modules/travel/utils/interfaces";
import { RootState } from "../../../store";
import { createSlice } from "@reduxjs/toolkit";

interface IDetails {
  beneficiaryDetails: any;
  dob: string;
  AgeDetails: IHealthAgeDetails;
  leadDetails: IHealthQuotation;
  quoteID: string | undefined;
}

const initialState: IDetails = {
  AgeDetails: {},
  leadDetails: {},
  quoteID: undefined,
  dob: "",
  beneficiaryDetails: {}
};

// eslint-disable-next-line import/no-unused-modules
export const userDetails = createSlice({
  name: "user",
  initialState,
  reducers: {
    AddDetails: (state, payload) => {
      state.AgeDetails = payload.payload;
    },
    AddDOB: (state, payload) => {
      state.dob = payload.payload;
    },
    AddLeadDetails: (state, payload) => {
      state.leadDetails = payload.payload;
    },
    AddQuoteId: (state, payload) => {
      state.quoteID = payload.payload;
    },

    // eslint-disable-next-line no-param-reassign, no-unused-vars, @typescript-eslint/no-unused-vars
    ResetUser: state => (state = initialState)
  }
});

// Action creators are generated for each case reducer function
export const { AddDetails, AddLeadDetails, AddQuoteId, ResetUser, AddDOB } = userDetails.actions;

export default userDetails.reducer;

export const getUserDetails = (state: RootState): IDetails => state.user;
export const getLeadDetails = (state: RootState): IHealthQuotation => state.user.leadDetails;
export const getBeneficiaryDetails = (state: RootState): any => state.user.beneficiaryDetails;
