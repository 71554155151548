export const DEBOUNCE_DELAY_IN_MS = 500;
export const DEBOUNCE_LONG_DELAY_IN_MS = 800;
export const DEBOUNCE_SHORT_DELAY_IN_MS = 300;

export const baseUrlV1 = "https://takafulbazaar.pk";
export const Status = {
  CREATED: 201,
  SUCCESS: 200,
  NOTACCEPTABLEEXCEPTION: 406
};

export const RateModeTypes = {
  ANNUAL: "annual",
  MONTHLY: "monthly"
};

export const ENVIRONMENTS = {
  LOCAL: "local",
  PRODUCTION: "production"
};

export const LineOfBusiness = {
  TRAVEL: "travel",
  HEALTH: "health",
  AUTO: "auto",
  PERSONALACCIDENT: "personalAccident",
  HOUSEHOLD: "houseHold"
};

export const Members = {
  SELF: "self",
  SPOUSE: "spouse",
  FATHER: "father",
  MOTHER: "mother",
  CHILD: "child"
};

export const PlanTypes = {
  FAMILY: "family",
  SELF: "self",
  PARENT: "parents"
};

export const CurrenyCode = {
  PKR: "PKR"
};

export const NumberToStringCounts = {
  1: "First",
  2: "Second",
  3: "Third",
  4: "Fourth"
};
export const yesNoConstants = [
  { label: "Yes", value: "true" },
  { label: "No", value: "false" }
];

export const UserTypes = {
  CUSTOMER: "customer"
};

export const Routes = {
  LANDING_PAGE: "health",
  HEALTH_PLAN_LISTING_PAGE: "plans",
  PERSONAL_ACCIDENT_PAGE: "personal-accident",
  AUTO_PAGE: "auto"
};

export const Environment = {
  Local: "local"
};

export const SocialUrls = {
  FACEBOOK: "https://www.facebook.com/takafulbazaarpk/",
  INSTAGRAM: "https://www.instagram.com/takafulbazaar.pk/?igshid=NTdlMDg3MTY%3D",
  LINKEDIN: "https://www.linkedin.com/company/takafulbazaar/",
  TWITTER: "https://twitter.com/takafulbazaar?s=11&t=h5V5Z51PfkvUAi06kZ39Ag",
  WHATSAPP: "https://wa.me/+923272055033",
  YOUTUBE: "https://www.youtube.com/channel/UChgsZk_nvaP2AGcB0n79QUg"
};

export const StatusCode = {
  NOT_FOUND: 404,
  CREATED: 201,
  SUCCESS: 200,
  UNAUTHORIZED: 401
};

export const ErrorMessages = {
  QUOTATION_NOT_FOUND: "Quotation not found"
};

export const Months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export const LineOfBusinessEnum = {
  HEALTH: "Health",
  AUTO: "Auto",
  FAMILY: "Family"
};


export const SortBy = [
  {
      id: 1,
      value: "name",
      namespace: "ASC",
      label: "Name Ascending Order",
      filter: "name-ASC",

  },
  {
      id: 2,
      value: "name",
      namespace: "DESC",
      label: "Name Descending Order",
      filter: "name-DESC",

  },
  {
      id: 3,
      value: "price",
      namespace: "ASC",
      label: "Price Ascending Order",
      filter: "price-ASC",
  },
  {
      id: 4,
      value: "price",
      namespace: "DESC",
      label: "Price Descending Order",
      filter: "price-DESC",
  },
];


export const TravelPolicyType = [
  {
      id: 1,
      value: "student",
      namespace: "student",
      label: "Student",
  },
  {
      id: 2,
      value: "individual",
      namespace: "individual",
      label: "Individual",
  },
  {
      id: 3,
      value: "family",
      namespace: "family",
      label: "Family",
  },
];


export const TripPurpose = [
  {
      id: 1,
      value: "single",
      namespace: "single",
      label: "Single",
  },
  {
      id: 2,
      value: "mutiple",
      namespace: "mutiple",
      label: "Multiple",
  },
];


export const TripType = [
  {
      id: 1,
      name: "single",
      value: "single",
      namespace: "single",
      label: "Single",
  },
  {
      id: 2,
      name: "mutiple",
      value: "mutiple",
      namespace: "mutiple",
      label: "Multiple",
  },
];

export const TripPurposes = [
  {
      id: 1,
      name: "Leisure",
      value: "leisure",
      namespace: "leisure",
      label: "leisure",
  },
  {
      id: 2,
      name: "Work",
      value: "work",
      namespace: "work",
      label: "work",
  },
  {
      id: 3,
      name: "Pilgrimage",
      value: "pilgrimage",
      namespace: "pilgrimage",
      label: "pilgrimage",
  },
];

export const PolicyTypeHardCoded = [
  {
      id: 6,
      name: 'individual',
      value: 'individual',
      namespace: 'individuals',
      label: 'individuals',
  },
  {
      id: 7,
      name: 'family',
      value: 'family',
      namespace: 'family',
      label: 'family',
  },
  {
      id: 9,
      name: 'student',
      value: 'student',
      namespace: 'student',
      label: 'student',
  }

]



//// this is serious

export const StudentType = {
  id: 9,
  name: 'student',
  value: 'student',
  namespace: 'student',
  label: 'Student',
}

export const FamilyType = {
  id: 7,
  name: 'family',
  value: 'family',
  namespace: 'family',
  label: 'Family',
}

export const IndividualType = {
  id: 6,
  name: 'individual',
  value: 'self',
  namespace: 'individual',
  label: 'Individual',
}


export const SingleTripType = {
  id: 1,
  label: 'Single Trip'
}

export const MultiTripType = {
  id: 2,
  label: 'Multiple Trip'
}

export const Income = [
  { option: "Self-Employed", value: "Self-Employed" },
  { option: "Salaried", value: "Salaried" },
  { option: "Employed", value: "Employed" },
];


export const RETURN_ROUTES = ["travel-result", "product-plan", "payment", "covered"]