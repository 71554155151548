import { IHealthCartPlans } from "../../../../modules/travel/utils/interfaces";
import { RootState } from "../../../store";
import { createSlice } from "@reduxjs/toolkit";

interface IHealthCart {
  plans: IHealthCartPlans;
  totalContribution: string;
  currentPayable: string;
  isCartbarOpen: boolean;
  rateModeType: string;
}

const initialState: IHealthCart = {
  plans: {},
  totalContribution: "",
  currentPayable: "",
  rateModeType: "",
  isCartbarOpen: false
};

// eslint-disable-next-line import/no-unused-modules
export const healthCart = createSlice({
  name: "healthCart",
  initialState,
  reducers: {
    AddPlanInCart: (state, { payload }) => {
      state.plans[payload.planType] = payload.planDetails;
      state.totalContribution = payload.totalContribution;
      state.currentPayable = payload.currentPayable;
      state.rateModeType = payload.rateModeType;
    },
    RemovePlanCart: (state, { payload }) => {
      const addedPlanTypes = Object.keys(state.plans);

      const newCart: IHealthCartPlans = {};
      addedPlanTypes.forEach(planType => {
        const isRemovingPlan = planType === payload;
        if (!isRemovingPlan) {
          newCart[planType as keyof typeof newCart] = state.plans[planType];
        }
      });

      state.plans = newCart;
    },
    updateCartBarStatus: (state, { payload }) => {
      state.isCartbarOpen = payload;
    },
    clearCartPlans: state => {
      state.plans = {};
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars, no-param-reassign
    ResetHealthCart: state => (state = initialState)
  }
});

export const { AddPlanInCart, RemovePlanCart, updateCartBarStatus, clearCartPlans, ResetHealthCart } = healthCart.actions;

export const getCartPlans = (state: RootState): IHealthCartPlans => state.healthCart.plans;

export const getRateModeType = (state: RootState): string => state.healthCart.rateModeType;

export const getCurrentPayable = (state: RootState): string => state.healthCart.currentPayable;

export const getCartBarStatus = (state: RootState): boolean => state.healthCart.isCartbarOpen;

export default healthCart.reducer;
